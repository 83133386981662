import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import css from "./informacoes.module.css";
import SvgBandeira from "../../../../assets/icons/informacoes/bandeira.png";
import SvgCarro from "../../../../assets/icons/informacoes/carro.png";
import SvgPreco from "../../../../assets/icons/informacoes/preco.png";
import SvgGarantia from "../../../../assets/icons/informacoes/garantia.png";

const BoxInfo = ({ img, tittle, subTittle }) => (
  <Col xs="12" sm="6" lg="3">
    <Row className={css.marginBottom}>
      <Col xs="3" className={css.imgCarro}>
        <img src={img} alt={tittle} />
      </Col>
      <Col>
        <Col className={css.tittleCarro}>{tittle}</Col>
        <Col className={css.subTittleCarro}>{subTittle}</Col>
      </Col>
    </Row>
  </Col>
);

export default function Informacoes() {
  return (
    <section className={css.secInfo}>
      <Container fluid="xxxl">
        <Row>
          <BoxInfo
            img={SvgBandeira}
            tittle="Marca de confiança"
            subTittle="Sempre a pensar em ti"
          />
          <BoxInfo
            img={SvgCarro}
            tittle="Viaturas Usadas"
            subTittle="Com qualidade a toda a prova"
          />
          <BoxInfo
            img={SvgPreco}
            tittle="Melhor preço"
            subTittle="Comprar aqui é poupar"
          />
          <BoxInfo
            img={SvgGarantia}
            tittle="Garantia"
            subTittle="Até 3 anos de Garantia"
          />
        </Row>
      </Container>
    </section>
  );
}
